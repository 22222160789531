<template>
    <v-container grid-list-md text-xs-center fluid mb-16>
        <h1>Pagamentos</h1>
        <v-divider class="mb-4"></v-divider>

        <FilterDateClientStore @changeDate="changeDate" @changeClient="changeClient" @changeStore="changeStore" />

        <v-layout wrap>
            <v-flex xs12 md6> </v-flex>
            <v-flex xs12 md6>
                <v-text-field :label="$t('Filtrar...')" v-model="filterName" clearable />
            </v-flex>
        </v-layout>

        <v-data-table :headers="headers" :items="listWithFilter" class="elevation-2" sort-by.sync="name">
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ $moment(item.createdAt).format("DD/MM/YYYY") }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-btn class="ma-2" color="success" dark small @click="payment(item)">
                    <v-icon dark small left>visibility</v-icon>
                    Detalhes
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import FilterDateClientStore from "@/components/shared/FilterDateClientStore";

export default {
    components: { FilterDateClientStore },

    data() {
        return {
            filterName: null,
            items: [],
            dates: [],
            selectedStore: null,
            selectedClient: null,
            headers: [
                { text: this.$t("Identificação"), value: "id", sortable: false },
                { text: this.$t("Unidade"), value: "store", sortable: true },
                { text: this.$t("Data Atendimento"), value: "createdAt", align: "center", sortable: true },
                { text: this.$t("Cliente"), value: "client", sortable: true },
                { text: this.$t("Ações"), value: "actions", align: "right", sortable: true },
            ],
        };
    },

    computed: {
        listWithFilter() {
            let ret = this.items;
            if (this.filterName) {
                let exp = new RegExp(
                    this.filterName
                        .trim()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, ""),
                    "i"
                );
                ret = ret.filter(
                    (item) =>
                        typeof item === "object" &&
                        item !== null &&
                        exp.test(
                            JSON.stringify(Object.values(item))
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                        )
                );
            }
            return ret;
        },
    },
    methods: {
        changeClient(event) {
            this.selectedClient = event;
            this.search();
        },
        changeDate(event) {
            this.dates = event;
            this.search();
        },
        changeStore(event) {
            this.selectedStore = event;
            this.search();
        },
        search() {
            let query = this.dates.length == 2 ? `?from=${this.dates[0]}&to=${this.dates[1]}` : `?from=${this.dates[0]}&to=${this.dates[0]}`;

            if (this.selectedClient) {
                query += `&client=${this.selectedClient.id}`;
            }
            if (this.selectedStore) {
                query += `&store=${this.selectedStore.id}`;
            }

            this.$http
                .get(`service/payment${query}&timezone=-3`)
                .then((result) => (this.items = result))
                .catch(() => (this.items = []));
        },
        payment(item) {
            this.$router.push({ path: `/payments/${item.id}` });
        },
    },
};
</script>